import(/* webpackMode: "eager", webpackExports: ["VisaIcon","MastercardIcon","SamsungPayIcon","VkIcon","OkIcon","YoutubeIcon","ZenIcon","Ddd3Icon","MarkerIcon","SendWhiteIcon"] */ "/data/components/Layout/components/FooterIcons/index.js");
;
import(/* webpackMode: "eager" */ "/data/components/Layout/components/FooterMenu/FooterMenu.module.css");
;
import(/* webpackMode: "eager" */ "/data/components/Layout/components/FooterContent/FooterContent.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/components/Layout/components/Header/Header.js");
;
import(/* webpackMode: "eager" */ "/data/components/Layout/components/Layout/Layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/components/Layout/components/LayoutUtils/LayoutUtils.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/components/Layout/components/Store/Store.tsx");
;
import(/* webpackMode: "eager" */ "/data/components/SvgIcon/SvgIcon.module.css");
;
import(/* webpackMode: "eager" */ "/data/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/data/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/data/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/data/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/data/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/data/src/shared/ui/Loaders/WithOverlay/WithOverlay.module.css");
;
import(/* webpackMode: "eager" */ "/data/src/shared/ui/Loaders/Radial/Radial.module.css");
;
import(/* webpackMode: "eager" */ "/data/src/shared/ui/Button/Button.module.css");
;
import(/* webpackMode: "eager" */ "/data/src/features/scroll-to-top-link/ScrollToTop.module.css");
;
import(/* webpackMode: "eager" */ "/data/styles/main.css");
;
import(/* webpackMode: "eager" */ "/data/styles/grid.css");
;
import(/* webpackMode: "eager" */ "/data/styles/fonts.css");
