import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/components/Carousel/Carousel.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/components/Main/components/BannerObserver/BannerObserver.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkArrowIcon","ArrowRightIcon","ArrowDownIcon","ArrowLinkIcon","PhoneIcon","ClockIcon","PointIcon","CheckArrowIcon"] */ "/data/components/Main/components/Icons/Icons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/components/Main/components/Main/Filters/Filters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/components/MiniReview/MiniReview.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/components/MobileFeaturesBanner/MobileFeaturesBanner.js");
;
import(/* webpackMode: "eager" */ "/data/components/Typography/Typography.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/controls/Button/Button.js");
;
import(/* webpackMode: "eager" */ "/data/controls/LightMarkdown/LightMarkdown.module.css");
;
import(/* webpackMode: "eager" */ "/data/controls/Picture/Picture.module.css");
;
import(/* webpackMode: "eager" */ "/data/components/Main/components/CollectionItem/CollectionItem.module.css");
;
import(/* webpackMode: "eager" */ "/data/components/Main/components/Main/Main.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/data/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/data/node_modules/next/dist/client/image-component.js");
