'use client';

import React from 'react';

import useAppMount from '@/application/config/useAppMount';
import { AppStoreContext } from '@/core/store/AppStoreProvider';
import { Cart } from '@/core/store/cart/type';


const Store = ({ cart, session, children }: { cart: Cart, session: any, children: React.ReactNode }) => {
  const store = useAppMount({
    cart,
    session,
  } as any);

  return (
    <AppStoreContext.Provider value={store}>
      {children}
    </AppStoreContext.Provider>
  );
}

export default Store;
