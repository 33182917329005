'use client';

import React from 'react';
import { usePathname } from 'next/navigation';

import { isLegacyStyleEnabled } from '@/core/lib';

const legacyStyle = `
  * {
    text-decoration: none;
    box-sizing: inherit;
    position: relative;
    margin: 0;
    padding: 0;
  }
`;

const LegacyStyle = () => {
  const pathname = usePathname();

  const isLegacyStyle = !!pathname && isLegacyStyleEnabled(pathname);

  return (
    <div>
      {isLegacyStyle ? <style key='legacy-style' id='legacy-style' dangerouslySetInnerHTML={{ __html: isLegacyStyle ? legacyStyle : '' }} /> : null}
    </div>
  );
};

export default LegacyStyle;
