import Link from 'next/link';

import singleCarpetIcon from '../../../../../../assets/imgs/header/singleCarpet.svg';
import { declOfNum } from '../../../../../../helpers/tools';
import PointIcon from '../../../../../../icons/PointIcon';
import SvgIcon from '../../../../../SvgIcon';
import Typography from '../../../../../Typography';

import RegionSelectLink from './components/RegionSelectLink';

import styles from './HeaderHints.module.css';

import returnIcon from '/assets/imgs/header/return.svg';
import starIcon from '/assets/imgs/header/star.svg';
import truckIcon from '/assets/imgs/header/truck.svg';


// TODO: add feature toggle for anticovid banner and discount banner
const HeaderHints = ({ shopsCount, currentShopsCount, region, isFreeShippingDisabled }) => {
  return (
    <div className={styles.headerHints}>
      <div className={styles.headerHintsContent}>
        <div className={styles.headerHintsShopLinks}>
          <Link prefetch={false} href='/adresa-magazinov' passHref legacyBehavior>
            <Typography
              variant='body3'
              component='a'
              bold
              className={styles.headerHintsShopLink}
              data-event-type='click'
              data-event='autoEvent'
              data-event-category='Верхнее меню'
              data-event-action='Адреса магазинов'
              data-event-label={`${shopsCount || 45} ${declOfNum(shopsCount || 45, ['магазин', 'магазина', 'магазинов'])}`}
            >
              <div className={styles.headerHintsIcon}><PointIcon /></div>
              {shopsCount}
              &nbsp;
              {declOfNum(shopsCount, ['магазин', 'магазина', 'магазинов'])}
            </Typography>
          </Link>

          {!!region && (
            <RegionSelectLink
              regionName={region?.name}
              cityShopsCount={region?.cityShopsCount ?? currentShopsCount}
            />
          )}
        </div>

        <div className={styles.headerHintsFeatures}>
          {!isFreeShippingDisabled && (
            <Link prefetch={false} href='/dostavka-i-oplata/#shipping' passHref legacyBehavior>
              <Typography variant='body3' component='a' className={styles.headerHintsFeature}>
                <SvgIcon height='24' width='24' className={styles.headerHintsIcon} svg={truckIcon} />
                Бесплатная доставка
              </Typography>
            </Link>
          )}

          <Link prefetch={false} href='/vozvrat' passHref legacyBehavior>
            <Typography variant='body3' component='a' className={styles.headerHintsFeature}>
              <SvgIcon height='24' width='24' className={styles.headerHintsIcon} svg={returnIcon} />
              Бесплатный возврат 100 дней
            </Typography>
          </Link>

          <Link prefetch={false} href='/catalog' passHref legacyBehavior>
            <Typography variant='body3' component='a' className={styles.headerHintsFeature}>
              <SvgIcon height='24' width='24' className={styles.headerHintsIcon} svg={singleCarpetIcon} />
              Все ковры в наличии
            </Typography>
          </Link>

          <Link prefetch={false} href='/reviews' passHref legacyBehavior>
            <Typography variant='body3' component='a' className={styles.headerHintsReviews}>
              <div className={styles.headerHintsReviewIcon}>
                <SvgIcon height='24' width='24' svg={starIcon} />
              </div>
              Отзывы
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderHints;
