'use client';

import React, { useEffect, useState } from 'react';

import cookies from '../../../../../../constants/cookies';
import Button from '../../../../../../controls/Button';
import useCookie from '../../../../../../hooks/useCookie';

import styles from './RegionConfirm.module.css';

const BUTTON_CONFIRM = 'Да, всё верно';
const BUTTON_CHANGE = 'Нет, изменить';

const RegionConfirm = ({ isRegionConfirmationShowed, domain, region }) => {
  const [isShowed, setIsShowed] = useCookie(cookies.isRegionConfirmationShowed, isRegionConfirmationShowed || 0);

  useEffect(() => {
    let timer;

    const handleLoad = () => {
      timer = setTimeout(() => setIsShowed(1, { domain, expires: 90 }), 9000);

      global.window?.dataLayer?.push({
        event: 'autoEvent_ni',
        eventCategory: 'Выбор города',
        eventAction: 'Показ попапа',
        eventLabel: `${region?.name}`,
      });
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);

      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [domain, region?.name, setIsShowed]);

  const trackClickButton = (actionEvent) => {
    global.window?.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Выбор города',
      eventAction: `${actionEvent}`,
      eventLabel: `${region?.name}`,
    });
  };

  const handleClickConfirm = () => {
    setIsShowed(1, { domain, expires: 90 });
    trackClickButton(BUTTON_CONFIRM);
  };

  const handleClickChange = () => {
    setIsShowed(1, { domain, expires: 90 });
    trackClickButton(BUTTON_CHANGE);
  };

  if (+isShowed) {
    return null;
  }

  return (
    <div className={styles.regionConfirm}>
      <div className={styles.wrapper}>
        <div className={styles.question}>
          Ваш город -
          <span className={styles.name}>
            {' '}
            {region?.name}
            ?
          </span>
        </div>
        <div className={styles.answer}>
          <Button className={styles.confirmButton} onClick={handleClickConfirm}>
            {BUTTON_CONFIRM}
          </Button>
          <Button className={styles.changeButton} onClick={handleClickChange}>
            {BUTTON_CHANGE}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegionConfirm;
