'use client';

import { MODAL_KEY } from '@/core/store/ui';
import useAppUi from '@/core/store/ui/useAppUi';

import ArrowMiniDownIcon from '../../../../../../../../icons/ArrowMiniDownIcon';
import Typography from '../../../../../../../Typography';

import styles from '../../HeaderHints.module.css';

const RegionSelectLink = ({ cityShopsCount, regionName }) => {
  const { setModal } = useAppUi();

  const handleRegionSelectClick = () => {
    setModal({ id: MODAL_KEY.UPDATE_REGION });
  };

  const tpProps: any = {
    variant: 'body3',
    component: 'button',
    bold: true,
    className: styles.headerHintsRegionLink,
    onClick: handleRegionSelectClick,
    type: 'button',
    'data-event-type': 'click',
    'data-event': 'autoEvent',
    'data-event-category': 'Верхнее меню',
    'data-event-action': 'Выбор региона',
    'data-event-label': regionName,
  };

  return (
    <Typography {...tpProps}>
      {regionName}
      &nbsp; ({cityShopsCount}) &nbsp;
      <ArrowMiniDownIcon />
    </Typography>
  );
};

export default RegionSelectLink;
