import { MutableRefObject } from 'react';

export interface UiScrollContainer {
  active: boolean;
  ref: MutableRefObject<HTMLElement | null> | HTMLElement | null;
}

export interface UiFooter {
  active: boolean;
  ref: MutableRefObject<HTMLElement | null> | HTMLElement | null;
}

export const MODAL_KEY = {
  UPDATE_REGION: 'update-region',
  UPDATED_CART: 'updated-cart',
} as const;

export type ModalName = (typeof MODAL_KEY)[keyof typeof MODAL_KEY];

export interface UiModal {
  id: ModalName | null;
  state?: { [k: string]: any };
}

export const BANNER_ID = {
  BLACK_FRIDAY: 'black-friday',
  DEMONSTRATION: 'demonstration',
} as const;

export type BannerId = (typeof BANNER_ID)[keyof typeof BANNER_ID];

export enum BlackFridayStage {
  START = 0,
  PAUSE = 1,
  STOP = 2,
}

export enum BlackFridayPromocodeType {
  DEFAULT = 'promo',
  LUX = 'lux',
}

export interface BlackFridayState {
  timestamp: number;
  state: BlackFridayStage;
  type?: BlackFridayPromocodeType;
}

export interface UiBanner {
  id: BannerId | null;
  value: BlackFridayState | null;
}

export interface UiCounters {
  catalogCount: number;
}

export interface UiLayout {
  headerOffset: number;
  menu: {
    opened: boolean;
  };
  scrollContainer: UiScrollContainer;
  footer: UiFooter;
}

export interface AppUi {
  counters: UiCounters;
  banner?: UiBanner;
  layout: UiLayout;
  modal: UiModal;
  offset: {
    x: number;
    y: number;
  };
  onRedirect?: () => void;
}

export type AppUiStore = AppUi;

const DEF_VALUE: AppUi = {
  counters: {
    catalogCount: 0,
  },
  modal: {
    id: null,
  },
  layout: {
    headerOffset: 0,
    menu: { opened: false },
    scrollContainer: { active: false, ref: null },
    footer: { active: true, ref: null },
  },
  offset: {
    x: 0,
    y: 0,
  },
};

const createUiStore = () => ({
  ...DEF_VALUE,
});

export default createUiStore;
