'use client';

import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import cls from 'classnames';
import { Carousel as Nuka, useCarousel } from 'nuka-carousel';

import arrowLeft from '../../assets/imgs/common/arrow-left.svg';
import arrowLeftWithBorder from '../../assets/imgs/common/arrow-left-with-border.svg';
import arrowRight from '../../assets/imgs/common/arrow-right.svg';
import arrowRightWithBorder from '../../assets/imgs/common/arrow-right-with-border.svg';
import SvgIcon from '../SvgIcon';

import styles from './Carousel.module.css';


const CustomArrows = ({ theme, goToSlideRef }) => {
  const { currentPage, totalPages, wrapMode, goBack, goForward, goToPage } = useCarousel();

  const allowWrap = wrapMode === 'wrap';
  const enablePrevNavButton = allowWrap || currentPage > 0;
  const enableNextNavButton = allowWrap || currentPage < totalPages - 1;

  useEffect(() => {
    if (goToSlideRef) {
      goToSlideRef.current = goToPage;
    }
  }, [goToSlideRef]);

  return (
    <div className={styles.arrows}>
      {theme === 'new' ? (
        <button
          type='button'
          aria-label='Назад'
          disabled={!enablePrevNavButton}
          onClick={goBack}
          className={cls(styles.arrowButton, styles.new, styles.shadowed, { [styles.disabled]: !enablePrevNavButton })}
        >
          <SvgIcon width='40' height='40' svg={arrowLeftWithBorder} />
        </button>
      ) : (
        <button
          type='button'
          aria-label='Назад'
          disabled={!enablePrevNavButton}
          onClick={goBack}
          className={cls(styles.arrowButton, styles.shadowed, { [styles.disabled]: !enablePrevNavButton })}
        >
          <SvgIcon width='13' height='24' svg={arrowLeft} />
        </button>
      )}

      {theme === 'new' ? (
        <button
          type='button'
          aria-label='Вперед'
          disabled={!enableNextNavButton}
          onClick={goForward}
          className={cls(styles.arrowButton, styles.new, styles.shadowed, { [styles.disabled]: !enableNextNavButton })}
        >
          <SvgIcon width='40' height='40' svg={arrowRightWithBorder} />
        </button>
      ) : (
        <button
          type='button'
          aria-label='Вперед'
          disabled={!enableNextNavButton}
          onClick={goForward}
          className={cls(styles.arrowButton, styles.shadowed, { [styles.disabled]: !enableNextNavButton })}
        >
          <SvgIcon width='13' height='24' svg={arrowRight} />
        </button>
      )}
    </div>
  );
};

function renderDefaultBottomRightControls(options) {
  const { slideCount, currentSlide, goToSlide } = options;
  const points = new Array(slideCount || 0).fill(true);

  return (
    <div className={styles.points}>
      {points.map((point, i) => (
        <button
          key={`point-${i}`}
          type='button'
          onClick={() => goToSlide(i)}
          className={cls(styles.point, { [styles.selected]: currentSlide === i })}
        />
      ))}
    </div>
  );
}

const CarouselContent = memo(({
  className,
  containerClassName,
  theme,
  containerRef,
  goToSlideRef,
  handleAfterSlide,
  handleBeforeSlide,
  isLoaded,
  isAutoplayDisabled,
  isDefaultBottomRightControlsEnabled,
  children,
}) => {
  return (
    <div ref={containerRef} className={cls(styles.container, containerClassName, { [styles.loaded]: isLoaded })}>
      <Nuka
        className={cls(styles.customCarousel, className, { [styles.paddedBottom]: isDefaultBottomRightControlsEnabled })}
        wrapAround
        showArrows
        autoplay={process.env.NODE_ENV === 'production' && !isAutoplayDisabled}
        autoplayInterval={8000}
        afterSlide={handleAfterSlide}
        beforeSlide={handleBeforeSlide}
        width='100%'
        heightMode='max'
        arrows={<CustomArrows theme={theme} goToSlideRef={goToSlideRef} />}
        disableAnimation={false}
        renderTopCenterControls={null}
      >
        {children}
      </Nuka>
    </div>
  );
});

const Carousel = (props = {}) => {
  const {
    goToSlideRef,
    className,
    containerClassName,
    children,
    isAutoplayDisabled,
    isDefaultBottomRightControlsEnabled,
    afterSlide,
    beforeSlide,
    theme,
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);

  const containerRef = useRef();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleAfterSlide = useCallback((i) => {
    if (afterSlide) {
      afterSlide(i);
    }
  }, [afterSlide]);

  const handleBeforeSlide = useCallback((i) => {
    if (beforeSlide) {
      beforeSlide(i);
    }
  }, [beforeSlide]);

  return (
    <CarouselContent
      className={className}
      containerClassName={containerClassName}
      theme={theme}
      containerRef={containerRef}
      goToSlideRef={goToSlideRef}
      handleAfterSlide={handleAfterSlide}
      handleBeforeSlide={handleBeforeSlide}
      isLoaded={isLoaded}
      isAutoplayDisabled={isAutoplayDisabled}
      isDefaultBottomRightControlsEnabled={isDefaultBottomRightControlsEnabled}
    >
      {children}
    </CarouselContent>
  );
};

export default memo(Carousel);
