'use client';

import React from 'react';
import classnames from 'classnames';

import SvgIcon from 'components/SvgIcon';

import ddd3Icon from '../../../../assets/imgs/footer/3ddd.svg';
import mastercardIcon from '../../../../assets/imgs/footer/mastercard.svg';
import okIcon from '../../../../assets/imgs/footer/ok.svg';
import samsungPayIcon from '../../../../assets/imgs/footer/samsungpay.svg';
import visaIcon from '../../../../assets/imgs/footer/visa.svg';
import vkIcon from '../../../../assets/imgs/footer/vk.svg';
import youtubeIcon from '../../../../assets/imgs/footer/youtube.svg';
import zenIcon from '../../../../assets/imgs/footer/zen.svg';

import markerIcon from './assets/marker.svg';
import sendWhiteIcon from './assets/send-white.svg';

import styles from './FooterIcons.module.css';

export const VisaIcon = () => <SvgIcon className={styles.footerScsItem} svg={visaIcon} style={{ background: '#fff' }} height='15' />
export const MastercardIcon = () => <SvgIcon className={styles.footerScsItem} svg={mastercardIcon} height='15' />
export const SamsungPayIcon = () => <SvgIcon className={styles.footerScsItem} svg={samsungPayIcon} height='15' />
export const VkIcon = () => <SvgIcon svg={vkIcon} height='24' />
export const OkIcon = () => <SvgIcon svg={okIcon} height='24' />
export const YoutubeIcon = () => <SvgIcon svg={youtubeIcon} height='24' />
export const ZenIcon = () => <SvgIcon svg={zenIcon} height='24' />
export const Ddd3Icon = () => <SvgIcon svg={ddd3Icon} height='24' width='58' />
export const MarkerIcon = () => <SvgIcon height='14' width='10' className={classnames(styles.footerInfoLinkIcon, styles.marker)} svg={markerIcon} />
export const SendWhiteIcon = () => <SvgIcon width='15' height='12' className={classnames(styles.footerInfoLinkIcon, styles.send)} svg={sendWhiteIcon} />
