import React from 'react';
import classnames from 'classnames';

import styles from './SvgIcon.module.css';

interface SvgIconProps {
  svg: {
    url: string;
    viewBox: string;
  };
  className?: string;
  isAutoSizeDisabled?: boolean;
  onClick?: () => void;
  width?: string | number;
  height?: string | number;
}

const SvgIcon = (props: SvgIconProps) => {
  const {
    svg,
    className,
    onClick,
    ...otherProps
  } = props;

  const Component: any = svg;

  return (
    <Component
      className={classnames(styles.svgIcon, { [styles.isPointerEventsEnabled]: !!onClick }, className)}
      {...otherProps}
    />
  );

  // const attributes = {
  //   viewBox: svg.viewBox || '0 0 16 16',
  //   url: svg.url || '',
  //   width,
  //   height,
  // };
  //
  // if (!isAutoSizeDisabled && !height && !width) {
  //   const [, , vWidth, vHeight] = svg?.viewBox?.split?.(' ') ?? [];
  //
  //   attributes.width = vWidth;
  //   attributes.height = vHeight;
  // }
  //
  // const { url, ...otherAttrs } = attributes;
  //
  // if (!url) {
  //   return null;
  // }
  //
  // return (
  //   <svg
  //     className={classnames(styles.svgIcon, { [styles.isPointerEventsEnabled]: !!onClick }, className)}
  //     onClick={onClick}
  //     {...otherAttrs}
  //     {...otherProps}
  //   >
  //     <use href={url} />
  //   </svg>
  // );
};

export default SvgIcon;
