import React, { forwardRef, useMemo } from 'react';
import classnames from 'classnames';
import ExtendedMarkdown from 'markdown-to-jsx';

import { NEXT_PUBLIC_CDN_ROOT } from '../../cfg';
import Typography from '../../components/Typography';
import Button from '../Button';

import styles from './LightMarkdown.module.css';

interface MarkdownProps {
  className?: string;
  source: string;
  style?: any,
  theme?: string,
  isExtendedMode?: boolean,
  isSingleFontEnabled?: boolean,
  isTurboMode?: boolean,
  isAmp?: boolean,
  header?: React.ReactNode,
  footer?: React.ReactNode,
}

const LightMarkdown = forwardRef<HTMLDivElement, MarkdownProps>((props: MarkdownProps, ref) => {
  const {
    className,
    source: rawSource,
    style,
    theme,
    isSingleFontEnabled,
    header = null,
    footer = null
  } = props;

  let content: any = null;

  const baseUrl = NEXT_PUBLIC_CDN_ROOT;

  const source = useMemo(() => rawSource ? rawSource?.replace(/<img /g, '<img loading=\'lazy\'') : rawSource, [rawSource]);

  content = (
    <ExtendedMarkdown
      options={{
        overrides: {
          Button: {
            component: Button
          },
          Typography: {
            component: Typography
          },
        },
        createElement(type, properties, children) {
          const newProperties = { ...properties };

          let newType = type;

          if (newType === 'img') {
            newProperties.loading = 'lazy';

            if (newProperties?.src && newProperties?.src?.toString().startsWith('/')) {
              newProperties.src = `${baseUrl}${newProperties.src}`;
            }
          }

          if (newType === 'p' && newProperties?.class) {
            newType = 'div';
          }

          return React.createElement(newType, newProperties, children);
        }
      } as any}
    >
      {source}
    </ExtendedMarkdown>
  );

  return (
    <div
      className={classnames(
        !theme && styles.markdown,
        className,
        {
          [styles.singleFontMode]: !!isSingleFontEnabled,
          [styles.promoMarkdown]: theme === 'promo'
        }
      )}
      style={style}
      ref={ref}
    >
      {header}
      {content}
      {footer}
    </div>
  );
});

LightMarkdown.displayName = 'Markdown';

export default LightMarkdown;
