import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Typography.module.css';


const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  span: 'span',
  div: 'div',
  link: 'a',
  button: 'button',
};

const Typography = React.forwardRef((props, ref) => {
  const {
    className,
    color = 'initial',
    component,
    variant = 'body1',
    bold,
    semibold,
    medium,
    regular,
    light,
    text,
    serif,
    uppercase,
    sans,
    strike,
    redesign,
    children,
    ...other
  } = props;

  const Component = component || defaultVariantMapping[variant] || 'span';

  // TODO: Ввести темы и убрать legacy
  return (
    <Component
      className={classnames(
        redesign ? styles.typography : styles.legacyTypography,
        {
          [styles[variant]]: styles[variant] && variant !== 'inherit',
          [styles[color]]: styles[color] && color !== 'initial',
          [styles.semibold]: semibold,
          [styles.medium]: medium,
          [styles.regular]: regular,
          [styles.light]: light,
          [styles.bold]: bold,
          [styles.serif]: serif,
          [styles.sans]: sans,
          [styles.strike]: strike,
          [styles.uppercase]: uppercase,
        },
        className,
      )}
      ref={ref}
      {...other}
    >
      {text || children}
    </Component>
  );
});

Typography.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'body2', 'body3', 'button', null]),
  bold: PropTypes.bool,
  semibold: PropTypes.bool,
  regular: PropTypes.bool,
  light: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'accent', null]),
  component: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.node,
  children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.string])
};

Typography.defaultProps = {
  variant: null,
  bold: false,
  semibold: false,
  color: null,
  component: null,
  className: null,
  text: '',
  children: null,
};

Typography.displayName = 'Typography';


export default Typography;
