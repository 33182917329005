'use client';

import checkArrowIcon from '../../../../assets/imgs/main/checkArrow.svg';
import clockIcon from '../../../../assets/imgs/main/clock.svg';
import linkArrowIcon from '../../../../assets/imgs/main/linkArrow.svg';
import phoneIcon from '../../../../assets/imgs/main/phone.svg';
import pointIcon from '../../../../assets/imgs/main/point.svg';
import arrowDownIcon from '../../../../icons/ArrowDownIcon/arrowDown.svg';
import arrowRightIcon from '../../../../icons/ArrowRightIcon/assets/arrowRight.svg';
import SvgIcon from '../../../SvgIcon';
import arrowLinkIcon from '../Main/assets/arrow-link.svg';


export const LinkArrowIcon = (props: any) => <SvgIcon width='15' height='10' svg={linkArrowIcon} {...props} />
export const ArrowRightIcon = (props: any) => <SvgIcon width='8' height='14' svg={arrowRightIcon} {...props} />
export const ArrowDownIcon = (props: any) => <SvgIcon width='16' height='9' svg={arrowDownIcon} {...props} />
export const ArrowLinkIcon = (props: any) => <SvgIcon width='15' height='10' svg={arrowLinkIcon} {...props} />
export const PhoneIcon = (props: any) => <SvgIcon height='14' width='14' svg={phoneIcon} {...props} />
export const ClockIcon = (props: any) => <SvgIcon height='14' width='14' svg={clockIcon} {...props} />
export const PointIcon = (props: any) => <SvgIcon height='15' width='11' svg={pointIcon} {...props} />
export const CheckArrowIcon = (props: any) => <SvgIcon width='18' height='15' svg={checkArrowIcon} {...props} />
