import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import { ColorItem } from '@/core/model/type/color';

import CloseIcon from '../../../../../../icons/CloseIcon';
import SearchIcon from '../../../../../../icons/SearchIcon';
import HeaderDropdownAction from '../HeaderDropdownAction';
import HeaderDropdownMenu from '../HeaderDropdownMenu';

import styles from './HeaderContent.module.css';

interface HeaderContentProps {
  colors: ColorItem[];
  allArticles: any;
}


const DynamicChoiceDropdown = dynamic(() => import('../ChoiceDropdown'), { ssr: false });

const HeaderContent = (props: HeaderContentProps) => {
  const { colors, allArticles } = props;

  const searchInputRef: any = useRef<HTMLInputElement | undefined>();

  const [openedDropdown, setOpenedDropdown] = useState<string>();

  const makeHandleToggleDropdown =
    (key: string) =>
    ({ isOpen }) => {
      if (isOpen) {
        // setLayout({ menu: { opened: true } });
        setTimeout(() => {
          setOpenedDropdown(key);
        }, 100);
      }

      if (!isOpen && key === openedDropdown) {
        // setLayout({ menu: { opened: false } });
        setTimeout(() => {
          setOpenedDropdown('');
        }, 100);
      }
    };

  const makeHandleCloseDropdown = (key) => () => {
    if (key === openedDropdown) {
      setOpenedDropdown('');
    }
  };

  const handleClearSearchClick = () => {
    if (searchInputRef?.current?.value) {
      searchInputRef.current.value = '';

      searchInputRef.current.focus();
    }
  };

  const handleRenderChoiceDropdown = () => {
    return (
      <DynamicChoiceDropdown
        className={styles.choiceDropdown}
        onCloseDropdown={makeHandleCloseDropdown('blog')}
        allArticles={allArticles}
      />
    );
  }

  return (
    <div className={classnames(styles.headerContent, 'header-content')}>
      <div className={styles.headerContentGroups}>
        <div className={styles.headerContentGroup}>
          <HeaderDropdownMenu
            isOpen={openedDropdown === 'all'}
            colors={colors}
            makeHandleCloseDropdown={makeHandleCloseDropdown}
            onToggleDropdown={makeHandleToggleDropdown('all')}
          />

          <HeaderDropdownAction prefetch={false} href='/adresa-magazinov/' title='Адреса магазинов' />

          <HeaderDropdownAction prefetch={false} href='/designer/' title='Дизайнерам' />

          <HeaderDropdownAction prefetch={false} href='/atelier/' title='Ковры на заказ' />

          <HeaderDropdownAction prefetch={false} href='/kontakty/' title='Контакты' />

          <HeaderDropdownAction prefetch={false} href='/decorators/' title='Наши декораторы' />
        </div>

        <div className={styles.headerContentGroupGap} />

        <div className={classnames(styles.headerContentGroup, styles.wide)}>
          <form className={styles.action}>
            <label htmlFor='search-desktop' className={styles.actionIcon}>
              <SearchIcon />
            </label>

            <input
              id='search-desktop'
              ref={searchInputRef}
              className={classnames(styles.actionInput, 'search-input', 'search-input__desktop')}
              placeholder='Поиск'
            />

            <button type='button' className={styles.actionClose} onClick={handleClearSearchClick}>
              <CloseIcon />
            </button>
          </form>
        </div>

        <div className={styles.headerContentGroupGap} />

        <div className={styles.headerContentGroup}>
          <HeaderDropdownAction
            title='Как выбрать ковёр'
            isHoverable
            isOpen={openedDropdown === 'blog'}
            onToggleDropdown={makeHandleToggleDropdown('blog')}
            onRenderDropdown={handleRenderChoiceDropdown}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderContent;
