'use client';

import Head from 'next/head';

import Error from '../components/Error';


const Page = ({ error, reset }: { error: Error & { digest?: string }, reset: () => void }) => {
  return (
    <>
      <Head>
        <meta name='robots' content='noindex' key='robots' />
      </Head>

      <Error />
    </>
  );
}

export default Page;
