import { ResponseError } from './type';

const isEmpty = value => {
  return (
    value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export const isFalsyOrEmpty = (value: unknown) => {
  if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
    return !value;
  }

  return isEmpty(value);
};

const DEF_FILTER_FN = (entry: [string, any]) => {
  const [, value] = entry ?? [];
  return !isFalsyOrEmpty(value);
};

const filterObjectValues = (value: Record<string, any>, filterFn: (value: any) => boolean = DEF_FILTER_FN) =>
  Object.entries(value).reduce((acc: Record<string, any>, entry: [string, any]) => {
    const [key, value] = entry ?? [];

    if (!filterFn(entry)) {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});

const isValidError = (error: unknown) =>
  typeof error === 'object' &&
  error !== null &&
  ('response' in error || 'request' in error || 'config' in error || 'message' in error);

export function toResponseError(maybeError: unknown) {
  if (isValidError(maybeError)) {
    return maybeError as ResponseError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toResponseError(error)?.message;
}

export const transformAxiosError = (error: any, transformer?: (value: any) => any): ResponseError => {
  const { response, message, code, name } = error || {};
  const { status, data } = response || {};

  if (transformer) {
    return transformer(error);
  }

  return filterObjectValues({
    name,
    message,
    status,
    code,
    data,
  }) as ResponseError;
};

export const getUrl = (url: string, query: Record<string, any>) => {
  const urlObj = new URL(url);

  Object.entries(query).forEach(([key, value]) => {
    urlObj.searchParams.append(key, value);
  });

  return urlObj.toString();
};
