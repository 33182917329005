import MainFilter, { MainFilterCheckboxValue, MainFilterColorValue, MainFilterRadioValue } from '@/core/model/main-filter/type';

const useMainFilters = (filters: MainFilter[]) => {
  const colorFilter = (filters?.length ? filters?.find((item) => item.id === 'color') : null) as MainFilter<MainFilterColorValue> | null | undefined;
  const sizeFilter = (filters?.length ? filters?.find((item) => item.id === 'size') : null) as MainFilter<MainFilterRadioValue | MainFilterCheckboxValue> | null | undefined;

  const colorFilterItems = colorFilter?.values;
  const sizeFilterItems = sizeFilter?.values;

  const priceFilter = (filters?.length ? filters?.find((item) => item.id === 'price') : null) as MainFilter<MainFilterRadioValue | MainFilterCheckboxValue> | null | undefined
  const shapeFilter = (filters?.length ? filters?.find((item) => item.id === 'shape') : null) as MainFilter<MainFilterRadioValue | MainFilterCheckboxValue> | null | undefined;

  const priceFilterItems = priceFilter?.values;
  const shapeFilterItems = shapeFilter?.values;

  return {
    colorFilterItems,
    sizeFilterItems,
    priceFilterItems,
    shapeFilterItems,
  };
};

export default useMainFilters;
