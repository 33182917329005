'use client';

import { useEffect } from 'react';

import { sendViewPromotionEvent } from '../../../../helpers/analytics';


const BannerObserver = () => {
  useEffect(() => {
    const handleBannerObserver = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target.classList.contains('banner-item')) {
          sendViewPromotionEvent({ element: entry.target });

          observer.unobserve(entry.target);
        }
      });
    };

    const newBannerObserver = new IntersectionObserver(handleBannerObserver, {
      root: document.querySelector('.banner-items'),
      rootMargin: '0px',
      threshold: 0.5
    });

    const bannerItems = document.querySelectorAll('.banner-item');

    bannerItems.forEach((item) => {
      newBannerObserver.observe(item);
    });

    return () => {
      newBannerObserver.disconnect();
    };
  }, []);


  return null;
}

export default BannerObserver;
