import { useCallback, useEffect, useMemo, useState } from 'react';

import useAppUser from '@/core/model/user/store/useAppUser';
import { useAppStoreApi } from '@/core/store/AppStoreProvider';
import useAppStore from '@/core/store/useAppStore';
import { toResponseError } from '@/shared/api/util';
import useToggle from '@/shared/hooks/useToggle';
import { Id } from '@/shared/types';


declare const window: Window & {
  dataLayer: Record<string, unknown>[];
  yaCounter37203035: { reachGoal: (event: string) => void };
};

const useAppUserFavorites = () => {
  const [isLoading, toggleLoading] = useToggle(false);

  const { setState } = useAppStoreApi();
  const user = useAppUser();
  const list = useAppStore((state) => state?.user?.favorites);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  const isInFavorites = useCallback(
    (id: Id) => {
      if (!user?.authorized && !isLoaded) {
        return false;
      }

      return !!list?.find((i) => +i === id);
    },
    [list, user?.authorized, isLoaded]);

  const setAppFavorites = useCallback(
    (payload: Id[]) => {
      setState((state) => {
        state.user.favorites = payload;
      });
    },
    [setState]
  );

  const addToFavorites = useCallback(
    async (id: Id) => {
      try {
        const payload = [...(list ?? []), id];

        if (user?.authorized) {
          toggleLoading(true);
          const response = await fetch(
            `${process.env.NEXT_PUBLIC_API_ROOT}/rest/favorites${id ? `id=${id}` : ''}`,
            {
              method: 'POST',
              cache: 'no-store',
              credentials: 'include',
              // body: JSON.stringify(payload),
            },
          );

          const result = await response.json();

          toggleLoading(false);

          if (!Array.isArray(result)) {
            const update = result?.products?.data.map((item) => +item.id);
            setAppFavorites(update);
          }

          return;
        }

        setAppFavorites(payload);

        if (typeof window !== 'undefined') {
          window?.dataLayer?.push({
            event: 'add_to_wishlist',
            ecommerce: {
              item: {
                products: [payload],
              },
            },
          });

          if (window.yaCounter37203035) {
            window.yaCounter37203035.reachGoal('add_to_wishlist');
          }
        }

        return payload;
      } catch (error: unknown) {
        console.error('[user][api][postFavorites]', { error });

        throw toResponseError(error);
      }
    },
    [user?.authorized, list, setAppFavorites, toggleLoading]
  );

  const removeFromFavorites = useCallback(
    async (id: Id) => {
      try {
        const payload = list.filter((i) => +i !== +id);

        if (user?.authorized) {
          toggleLoading(true);
          const response = await fetch(
            `${process.env.NEXT_PUBLIC_API_ROOT}/rest/favorites${id ? `id=${id}` : ''}`,
            {
              method: 'DELETE',
              cache: 'no-store',
              credentials: 'include',
            },
          );

          const result = await response.json();

          if (Array.isArray(result) && result.length === 0) {
            setAppFavorites([]);
            toggleLoading(false);
            return;
          }

          if (!Array.isArray(result)) {
            const update = result.products.data.map((item) => +item.id);
            setAppFavorites(update);
            toggleLoading(false);
            return;
          }

          throw new Error('Undefined response data while deleting favorite product from a list');
        }

        setAppFavorites(payload);

        return payload;
      } catch (error: unknown) {
        console.error('[user][api][deleteFavorites]', { error });

        throw toResponseError(error);
      }
    },
    [list, user?.authorized, setAppFavorites, toggleLoading]
  );

  return useMemo(
    () => ({
      isLoading,
      list,
      isInFavorites,
      addToFavorites,
      removeFromFavorites,
    }),
    [isLoading, list, isInFavorites, addToFavorites, removeFromFavorites]
  );
};

export default useAppUserFavorites;
