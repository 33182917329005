'use client';

import { useEffect } from 'react';
import dynamic from 'next/dynamic';

import { RegionFull } from '@/core/model/region/type';
import LegacyStyle from '@/shared/ui/LegacyStyle';

const DynamicLayoutEffects = dynamic(() => import('@/shared/ui/LayoutEffects'), { ssr: false });
const DynamicLayoutEvents = dynamic(() => import('@/shared/ui/LayoutEvents'), { ssr: false });
const DynamicLayoutDataLayer = dynamic(() => import('@/shared/ui/LayoutDataLayer'), { ssr: false });
const DynamicModals = dynamic(() => import('../Modals'), { ssr: false });
const DynamicDataLayerEventManager = dynamic(() => import('../DataLayerEventManager'), { ssr: false });

interface LayoutUtilsProps {
  domain: any,
  region: RegionFull,
  isFreeShippingDisabled: boolean,
  sessionCookies: { [key: string]: string }[],
  setSessionCookies: ({ sessionCookies }: { sessionCookies?: { [key: string]: string }[] }) => Promise<void>,
}

const LayoutUtils = ({ domain, region, isFreeShippingDisabled, sessionCookies, setSessionCookies }: LayoutUtilsProps) => {
  useEffect(() => {
    if (sessionCookies?.length) {
      setSessionCookies({ sessionCookies });
    }
  }, [sessionCookies, setSessionCookies]);

  return (
    <>
      <DynamicLayoutEffects domain={domain} region={region} />
      <DynamicLayoutEvents domain={domain} />
      <DynamicLayoutDataLayer />

      <DynamicDataLayerEventManager />

      <LegacyStyle />

      <DynamicModals region={region} isFreeShippingDisabled={isFreeShippingDisabled} />
    </>
  );
};


export default LayoutUtils;
